import { P500 } from "@stackchat/colors"
import * as React from "react"
import styled from "styled-components"
import Layout from "templates/layout"

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${P500 };
`

export default class Four0Four extends React.Component {
  render() {
    return (
      <Layout title="Not Found" alwaysOnHeader={true}>
        <style
          dangerouslySetInnerHTML={{
            __html: `
              header.container-fluid.d-flex, footer { 
                display: none !important; 
              }
            `
          }}
        />
        <Container>
          <h1 style={{ fontSize: 100 }}>😟</h1>
          <h1 style={{ color: "#fff" }}>The page you are requesting was not found.</h1>
        </Container>
      </Layout>
    )
  }
}
